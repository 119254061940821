<template>
    <div class="container card b-1">
        <div class="container w-65r fl-eq pt-4 pb-4">
            <div class="bg-2 fl-eq-c" style="width: 50%;">
                <div @click="errorMessage=''">
                    <div class="mb-5">
                        <h3>Set A New Password</h3>
                    </div>
                    <form>
                        <label>Existing Password</label>
                        <validated-input placeholder="Enter your Existing Password" :type="passwordType"
                                         v-model="model.password" class="mb-4"
                                         :disabled="loading" :rules="{required: true}"/>
                        <label>Enter new Password</label>
                        <validated-input placeholder="Enter your Password" :type="passwordType"
                                         v-model="model.password" class="mb-4"
                                         :disabled="loading" :rules="{required: true}"/>
                        <span class="text-danger" v-if="errorMessage" v-html="errorMessage"></span>
                        <label>Confirm new Password</label>
                        <validated-input placeholder="Enter your Password" :type="passwordType"
                                         v-model="model.confirm_password" class="mb-0"
                                         :disabled="loading" :rules="{required: true}"/>
                        <validated-checkbox v-model="showPassword" label="Show password"></validated-checkbox>
                        <span class="text-danger" v-if="errorMessage" v-html="errorMessage"></span>
                        <div class="btn-group">
                            <btn class="btn-block btn-basic-b" text="Cancel" size="md" :disabled="loading" :loading="loading"/>
                            <btn class="btn-block btn-basic-b" text="Update" loading-text="Updating..." size="md"
                                 :disabled="loading" :loading="loading"/>
                        </div>
                        <div class="fl-x-c mt-4">
                            <i class="text-success fa fa-check-circle fa-2x p-2 br-1"></i>
                            <span>Your new password has been updated.<br/>Please <a href="/login-page/">login</a> to continue.</span>
                        </div>
                    </form>
                </div>
            </div>
            <div class="fl-x-cc bg-2" style="min-width: 50%">
<!--                <img src="../../../assets/images/logo.png" alt="logo">-->
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name : 'SetNewPasswordAfterLogin',
    data () {
        return {
            loading      : false,
            showPassword : false,
            passwordType : 'password',
            errorMessage : '',
            model        : {}
        };
    },
    watch : {
        showPassword : {
            handler () {
                this.showOrHidePassword();
            }
        }
    },
    methods : {
        showOrHidePassword () {
            if (this.showPassword === true) {
                this.passwordType = 'text';
            } else {
                this.passwordType = 'password';
            }
        }
    }
};
</script>

<style>
.full-page {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.link {
    cursor: pointer;
}
</style>
